import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { slideDownPositionAnimation } from '../../animations';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { IScenario, Scenario } from '../../../views/scenario/model/scenario.model';
import * as fromScenario from '../../../store/selectors/scenario.selectors';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { RunwayService } from '../../../views/scenario/reports/services/runway.service';
import { MatProgressButtonOptions } from '../../ui/spinner-button/mat-spinner-button.interface';
import { map, tap } from 'rxjs/operators';
import { PROCESSED_STATES } from '../../app.constants';
import { Dialog } from '@angular/cdk/dialog';
import {
  ScenarioDiffDialogComponent
} from '../../../views/scenario/dialogs/scenario-diff-dialog/scenario-diff-dialog.component';
import { JobStatus } from "../../../views/scenario/model/scenario-job-dto.model";

export class Notification {
  action: () => void;
  message: string;
  title: string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [slideDownPositionAnimation]
})
export class NotificationComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-present') public isPresent = false;
  notification: Notification;
  scenario$: Observable<Scenario>;
  loading = false;
  options: MatProgressButtonOptions = { stroked: true, spinnerColor: 'accent' };
  runwayServiceSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    protected runwayService: RunwayService,
    private dialog: Dialog
  ) {
  }

  protected _handleScenarioEvents(scenario) {
    this.isPresent = scenario.stale === true && this.isProcessed(scenario);
    this.loading = false;
  }

  handleAction(scenario: Scenario) {
    this.loading = true;
    if (scenario.locked) {
      return;
    }
    this.runwayServiceSubscription = this.runwayService.processScenario(scenario).subscribe();
  }

  isProcessed(scenario: Scenario): boolean {
    return PROCESSED_STATES.includes(scenario.currentScenarioJob.state);
  }

  isProcessing(scenario: Scenario): boolean {
    return !PROCESSED_STATES.includes(scenario.currentScenarioJob.state);
  }

  isNewOrDuplicated(scenario: Scenario): boolean {
    return scenario.currentScenarioJob.state === JobStatus.NEW;
  }

  ngOnInit() {
    this.scenario$ = combineLatest([
      this.store.pipe(select(fromScenario.selectScenario)),
      this.store.pipe(select(fromScenario.selectScenarioLatestJob)),
    ]).pipe(
      map(([scenario, job]) => ({
        ...scenario,
        currentScenarioJob: job ?? scenario.currentScenarioJob
      }))
    );
    this.scenario$.subscribe(this._handleScenarioEvents.bind(this));
  }

  ngOnDestroy(): void {
    this.runwayServiceSubscription?.unsubscribe();
  }

  scenarioChanges(scenario: Scenario) {
    this.runwayService.diff(scenario.id).subscribe(
      data => this.dialog.open(ScenarioDiffDialogComponent, {
        width: '640px',
        data
      })
    );
  }
}
