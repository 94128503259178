import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-list-item',
  templateUrl: './nav-list-item.component.html',
  standalone: true,
  imports: [NgClass]
})
export class NavListItemComponent {
  
  @Input() isScenarioNavLink: boolean = false;
  @Input() isReportNavLink: boolean = false;
  constructor() { }
}

