import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ScenarioTabsActions } from '../actions/scenario-tabs.actions';

export const scenarioTabsFeatureKey = 'scenarioTabs';

export interface State extends EntityState<number> {
  // additional entities state properties
  activeTab?: number
}

export const adapter: EntityAdapter<number> = createEntityAdapter<number>({
  selectId: (scenarioTab: number) => scenarioTab
});

export const initialState: State = adapter.getInitialState({
  activeTab: 0
});

export const reducer = createReducer(
  initialState,
  on(ScenarioTabsActions.addScenarioTab,
    (state, action) => adapter.addOne(action.scenarioTab, {
      ...state,
      activeTab: action.scenarioTab
    })
  ),
  on(ScenarioTabsActions.upsertScenarioTab,
    (state, action) => adapter.upsertOne(action.scenarioTab, state)
  ),
  on(ScenarioTabsActions.addScenarioTabs,
    (state, action) => adapter.addMany(action.scenarioTabs, {
      ...state,
      activeTab: action.activeTab || state.activeTab
    })
  ),
  on(ScenarioTabsActions.upsertScenarioTabs,
    (state, action) => adapter.upsertMany(action.scenarioTabs, {
      ...state,
      activeTab: action.activeTab || state.activeTab
    })
  ),
  on(ScenarioTabsActions.updateScenarioTab,
    (state, action) => adapter.updateOne(action.scenarioTabs, state)
  ),
  on(ScenarioTabsActions.updateScenarioTabs,
    (state, action) => adapter.updateMany(action.scenarioTabs, state)
  ),
  on(ScenarioTabsActions.deleteScenarioTab,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ScenarioTabsActions.deleteScenarioTabs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ScenarioTabsActions.clearScenarioTabs,
    state => adapter.removeAll(state)
  ),
  on(ScenarioTabsActions.setActiveTab, (state, action) => {
    return { ...state, activeTab: action.id };
  })
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
