import moment from 'moment';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ScenarioSegment } from '../../model/scenario-segments.model';
import { Scenario } from "../../model/scenario.model";

export interface UaOverride {
  productBlendedSegmentIds: string[]
  value: number
}

export interface ScenarioMarketingConfigForm {
  mode?: FormControl<string>;
  scenarioId?: FormControl<number>;
  spendLookbackDate?: FormControl<moment.Moment | string>;
  spendLookbackNumberOfDays?: FormControl<number>;
  spendLookbackRelative?: FormControl<boolean>;
  defaultPaidPercentage?: FormControl<number>;
  overrideProductPaidPercentage?: FormControl<boolean>;
  targetPayback?: FormControl<number>;
  roasTargetPercentage?: FormControl<number>;
  paybackMethod?: FormControl<string>;
  blendedSegmentsList?: FormArray<FormGroup<BlendedSegmentFormGroup>>;
  segmentCount?: FormControl<any>
  defaultMarketingFatigue?: FormControl<number>
  defaultCpiSensitivityToSpend?: FormControl<number>
  paidPercentageOverrides?: FormControl<UaOverride[]>
  cpiSensitivityToSpendOverrides?: FormControl<UaOverride[]>
  marketingFatigueOverrides?: FormControl<UaOverride[]>
  buyClv?: FormControl<boolean>
}

export interface BlendedSegmentFormGroup {
  paidPercentage: FormControl<number>;
  segmentId: FormControl<string>;
}

export class BlendedSegment {
  paidPercentage?: number
  segmentId?: string
  segment?: ScenarioSegment
}

export interface ProductBlendedSegmentNode {
  id: string
  name: string
}

export class ScenarioMarketingConfig {
  id?: number;
  mode?: string;
  scenarioId?: number;
  scenario?: Scenario;
  spendLookbackDate?: moment.Moment | string;
  spendLookbackNumberOfDays?: number
  spendLookbackRelative?: boolean
  defaultPaidPercentage?: number;
  targetPayback?: number;
  roasTargetPercentage?: number;
  paybackMethod?: string;
  mappedPaybackMethod?: string;
  blendedSegmentsList?: BlendedSegment[];
  blendedSegments?: ScenarioSegment[];
  segmentCount?: any;
  defaultCpiSensitivityToSpend?: number
  defaultMarketingFatigue?: number;
  overrideProductPaidPercentage?: boolean;
  overrideSegmentsForPaid?: ProductBlendedSegmentNode[]
  overrideSegmentsForPvc?: ProductBlendedSegmentNode[]
  marketingFatigueOverrides?: UaOverride[]
  paidPercentageOverrides?: UaOverride[]
  cpiSensitivityToSpendOverrides?: UaOverride[]
  buyClv?: boolean

  static fromJson(json: any): ScenarioMarketingConfig {
    return Object.assign(new ScenarioMarketingConfig(), {
      ...json,
      blendedSegmentsList: json?.blendedSegmentsList?.edges?.map((edge: any) => edge.node) || []
    });
  }

  static isTargetROAS(mode: string): boolean {
    return mode === MarketingMode.TARGET_PAYBACK || mode === MarketingMode.TARGET_DNU_PAYBACK;
  }
}

export enum PaybackMethods {
  BLENDED = 'BLENDED',
  PAID = 'PAID'
}

export enum MarketingMode {
  CURRENT_PAYBACK = 'CURRENT_PAYBACK',
  TARGET_NEW_USERS = 'TARGET_NEW_USERS',
  TARGET_SPEND = 'TARGET_SPEND',
  TARGET_PAYBACK = 'TARGET_PAYBACK',
  TARGET_DNU_PAYBACK = 'TARGET_DNU_PAYBACK',
}

export class PlotPoint {
  x: number
  y: number
}

interface IActual {
  spend?: number;
  cpi?: number;
  dnu?: number;
  clv?: number;
  payback?: number;
}

interface IModel {
  m?: number
  c?: number
}

export class PvcPreviewResponse {
  cpiBaseline: PlotPoint[]
  cpiFatigue: PlotPoint[]
  dnuBaseline: PlotPoint[]
  dnuFatigue: PlotPoint[]
  cpiActualLastYear: PlotPoint[]
  cpiActualLookback: PlotPoint[]
  dnuActualLastYear: PlotPoint[]
  dnuActualLookback: PlotPoint[]
  actualsAvg?: IActual
  pvModel?: IModel
}
