import { Component, inject } from "@angular/core";
import { SvgImagesComponent } from "../ui/svg-images/svg-images.component";
import { ThemeService } from "../services/theme.service";
import { AsyncPipe, NgClass } from "@angular/common";

@Component({
  selector: 'app-loading',
  template: `
    <div [ngClass]="(themeService.isDarkMode() | async) ? 'dark' : ''">
      <div
        class="absolute vh-100 w-100 top-0 bottom-0 left-0 right-0 inset-0 z-10 flex flex-col items-center bg-soft-blue dark:bg-slate-950 text-gray-300 dark:text-slate-800 transition-all dark:v2:!bg-background v2:bg-white">
        <div class="flex items-center justify-center mt-[40vh] p-20 relative">
          <div class="absolute inset-0 flex items-center justify-center">
            <app-svg-images svgName="spinner" class="h-24 w-24"></app-svg-images>
          </div>
          <div>
            <img src="/assets/images/logo-small-dark.png" class="h-5 dark:hidden">
            <img src="/assets/images/logo-small.png" class="h-5 hidden dark:block">
          </div>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [SvgImagesComponent, AsyncPipe, NgClass]
})
export class LoadingComponent {
  themeService = inject(ThemeService)
}
