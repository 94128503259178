<div class="flex items-center justify-between w-full active gap-2 font-normal"
  [ngClass]="{
    'py-2 px-3' : isScenarioNavGroupItem && !isReportNavGroupItem && !unStyled,
    'py-2.5 px-3.5' : !isScenarioNavGroupItem && !isReportNavGroupItem && !unStyled,
    'text-base rounded-lg group-[.is-active]/route:font-medium text-white/70 group-[.is-active]/route:bg-white/10 group-[.is-active]/route:text-white group-hover/menu:!bg-white group-hover/menu:!text-black' : !isReportNavGroupItem && !unStyled,
    'p-2 rounded hover:bg-white text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-900 text-left': isReportNavGroupItem && !unStyled,
    'dark:text-white': unStyled
    }">
  <div class="flex items-center gap-4 w-full">
    <ng-content></ng-content>
  </div>
  <div class="flex items-center space-x-1.5 justify-self-end">
    <ng-content select="[navListGroupTotalItems]"></ng-content>
    <app-svg-images svgName="chevron-right" class="h-3.5 group-hover/route:translate-x-1 transition-all"></app-svg-images>
  </div>
</div>
