import { Component, Input } from '@angular/core';
import { SvgImagesComponent } from '../../svg-images/svg-images.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-nav-list-group-item',
  templateUrl: './nav-list-group-item.component.html',
  standalone: true,
  imports: [NgClass, SvgImagesComponent]
})
export class NavListGroupItemComponent {
  @Input() isScenarioNavGroupItem: boolean = false;
  @Input() isReportNavGroupItem: boolean = false;
  @Input() unStyled: boolean = false;
  constructor() { }
}

