import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ScenarioTabsActions } from "../actions/scenario-tabs.actions";
import { ActivatedRoute, Router } from "@angular/router";
import { tap, withLatestFrom } from "rxjs/operators";
import { from, map, of, switchMap } from "rxjs";
import * as fromAuth from "../../views/user-management/redux/actions";
import * as fromProduct from "../actions";
import { Store } from "@ngrx/store";
import { selectFavouriteScenarios } from "../selectors";

/**
 * We are base64 encoding this so lets keep the keys as short as possible
 */
interface UrlState {
  /*
    * s = scenarios
    * a = activeTab
   */
  s: number[]
  a?: number
}

@Injectable()
export class ScenarioTabsEffects {
  defaultState: UrlState = {
    s: [],
    a: 0
  }

  constructor(private actions$: Actions,
              public router: Router,
              private store: Store,
              private route: ActivatedRoute) {
  }

  loadTabs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioTabsActions.loadScenarioTabs),
      withLatestFrom(this.route.queryParams, this.store.select(selectFavouriteScenarios)),
      switchMap(([action, { state }, favourites]) => {
        const params: UrlState = this.decodeUrlState(state);
        if (!state) {
          params.s = favourites.map(favourite => favourite.id)
          params.a = 0
        }
        return this.updateUrl(params, true).pipe(
          map(() => ScenarioTabsActions.addScenarioTabs({ scenarioTabs: params.s, activeTab: params.a }))
        )
      }),
    )
  })

  addTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioTabsActions.addScenarioTab),
      withLatestFrom(this.route.queryParams),
      switchMap(([action, { state }]) => {
        const params: UrlState = this.decodeUrlState(state);
        if (!params.s.includes(action.scenarioTab)) {
          params.s.push(action.scenarioTab)
        }
        if (!action.stopNavigation) {
          params.a = action.scenarioTab;
        }
        return this.updateUrl(params).pipe(
          map(() => ScenarioTabsActions.setActiveTab({ id: params.a }))
        )
      }),
    )
  })

  removeTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioTabsActions.deleteScenarioTab),
      withLatestFrom(this.route.queryParams),
      switchMap(([action, { state }]) => {
        const params: UrlState = this.decodeUrlState(state);
        const index = params.s.indexOf(action.id)
        params.s.splice(index, 1)
        if (params.a === action.id) {
          params.a = params.s[index - 1] ?? 0;
        }
        return this.updateUrl(params).pipe(
          map(() => ScenarioTabsActions.setActiveTab({ id: params.a }))
        )
      }),
    )
  })

  clearTabs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuth.organisationChanged, fromProduct.clearScenarios),
      map(() => ScenarioTabsActions.clearScenarioTabs())
    )
  })

  setActiveTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioTabsActions.setActiveTab),
      withLatestFrom(this.route.queryParams),
      switchMap(([action, { state }]) => {
        const params: UrlState = this.decodeUrlState(state);
        params.a = action.id
        return of(params)
      }),
      tap((params) => this.updateUrl(params))
    )
  }, { dispatch: false })

  private decodeUrlState(state: string): UrlState {
    return JSON.parse((state) ? atob(state) : JSON.stringify(this.defaultState))
  }

  updateUrl(params: UrlState, syncScenarios = false) {
    return from(this.router.navigate([], {
      queryParams: {
        state: btoa(JSON.stringify(params)),
        ...(params.s.length > 0 && syncScenarios && {
          Scenario: params.s.join(',')
        })
      },
      queryParamsHandling: 'merge'
    }))
  }

}
