import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Theme, ThemeService } from '../../services/theme.service';
import { AppState, selectIsReportingV2 } from 'src/app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent implements OnInit {
  currentTheme: Theme;
  themes: Theme[] = [];
  isReportingV2$: Observable<boolean>;
  isDarkMode$: Observable<boolean>;
  private destroyRef = inject(DestroyRef);
  private store = inject(Store<AppState>);

  constructor(public themeService: ThemeService) {}

  ngOnInit() {
    this.themes = this.themeService.getThemes();

    this.themeService.getCurrentTheme().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(theme => {
      this.currentTheme = theme;
    });

    this.isDarkMode$ = this.themeService.isDarkMode();
    this.isReportingV2$ = this.store.select(selectIsReportingV2);
  }

  isActiveTheme(theme: Theme): boolean {
    return this.currentTheme?.label === theme.label;
  }

  public toggleTheme(theme: Theme): void {
    this.themeService.setTheme(theme.label);
  }

  public switchTheme(): void {
    const theme = this.currentTheme.label === 'Dark' ? this.themes[1] : this.themes[0];
    this.themeService.setTheme(theme.label);
  }
}
