import { AfterContentInit, Attribute, Component, ContentChildren, DestroyRef, ElementRef, EventEmitter, HostBinding, HostListener, inject, Input, OnChanges, Output, QueryList, signal, SimpleChanges, ViewChild } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { merge, startWith, switchMap, tap } from 'rxjs';
import { OptionComponent } from './option/option.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type SelectValue<T> = T | T[] | null;

@Component({
  selector: 'app-select',
  template: `
    <div cdkOverlayOrigin #origin="cdkOverlayOrigin" data-custom-select
      [ngClass]="{
        'bg-digital-blue hover:bg-opacity-90 text-white font-medium border-blue-700': value,
        'hover:bg-soft-blue bg-white border-gray-300 text-gray-700': !value
      }"
      class="h-9 z-[1001] relative border shadow-sm rounded-md cursor-pointer flex items-center gap-4 justify-between text-[13px] pr-2 flex-1 pl-2.5">
      <span data-custom-select="label" class="text-nowrap">{{displayValue || label}}</span>
      @if (value) {
        <button type="button" (click)="clearSelection($event)" class="p-1 inline-flex">
          <app-svg-images svgName="x-mark" class="h-4 inline-block opacity-60"></app-svg-images>
        </button>
      } @else {
        <app-svg-images svgName="caret-down" 
          class="inline-flex h-5 opacity-60"
          [ngClass]="{
            'transform rotate-180': isOpen()
          }">
        </app-svg-images>
      }
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="origin"
      [cdkConnectedOverlayOpen]="isOpen()"
      [cdkConnectedOverlayOffsetY]="5"
      [cdkConnectedOverlayPositions]="[
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
        },  
      ]"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      (backdropClick)="close()"
      (detach)="close()">
      <div class="z-10 fade-in-down px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none box-border text-sm bg-white rounded-md border shadow-md">
        <div class="text-sm max-h-[40vh] flex flex-col justify-between overflow-y-hidden min-w-[250px]">
          <div class="space-y-0.5 p-1">
            <div class="flex items-center justify-between h-7 pl-1 pb-1">
              <h3 class="font-medium flex-1">{{ label }}</h3>
              @if (value) {
                <button (click)="clearSelection($event)" 
                  class="p-1 rounded active text-digital-blue font-medium hover:underline">
                  {{ 'filters.clear' | translate }}
                </button>
              }
            </div>
            @if (searchable) {
              <label [for]="label + 'search'" class="relative">
                <input type="text" 
                  #input
                  [name]="label + 'search'"
                  [id]="label + 'search'"
                  (input)="onHandleInput($event)"
                  class="w-full py-2 border border-gray-300 rounded-full p-4 shadow-inner">
                <div class="absolute top-0 right-2 bottom-0 flex items-center">
                  <app-svg-images svgName="magnifying-glass" class="pr-1 inline-flex h-3.5 text-gray-500"></app-svg-images>
                </div>
              </label>
            }
          </div>
          <div class="overflow-y-auto overflow-x-clip px-1">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true
    }
  ]
})
export class SelectComponent<T> implements OnChanges, AfterContentInit, ControlValueAccessor {

  @Input({ required: true }) label = '';
  @Input() searchable = false;
  @Input() disabled = false;
  @Input() displayWith: ((value: T) => string | number) | null = null;
  @Input() compareWith: ((v1: T | null, v2: T | null) => boolean) = (v1, v2) => v1 === v2;

  @Input() set value(value: SelectValue<T>) {
    this.setupValue(value);
    this.onChange(this.value);
    this.highlightSelectedOptions();
  };

  @Output() readonly opened = new EventEmitter<void>();
  @Output() readonly selectionChanged = new EventEmitter<SelectValue<T>>();
  @Output() readonly closed = new EventEmitter<void>();
  @Output() readonly searchChanged = new EventEmitter<string>();

  @ContentChildren(OptionComponent, { descendants: true }) options!: QueryList<OptionComponent<T>>;
  @ViewChild('input') searchInputEl!: ElementRef<HTMLInputElement>;

  protected isOpen = signal(false);
  protected onChange: (newValue: SelectValue<T>) => void = () => {};
  protected onTouched: () => void = () => {};

  private destroyRef = inject(DestroyRef);
  private selectionModel = new SelectionModel<T>(coerceBooleanProperty(this.multiple));
  private optionMap = new Map<T | null, OptionComponent<T>>();
  private listKeyManager!: ActiveDescendantKeyManager<OptionComponent<T>>;

  @HostBinding('attr.tabIndex')
  @Input()
  tabIndex = 0;

  constructor(
    @Attribute('multiple') private multiple: string | null,
    private hostEl: ElementRef
  ) { }

  get value() {
    if (this.selectionModel.isEmpty()) {
      return null;
    }
    if (this.selectionModel.isMultipleSelection()) {
      return this.selectionModel.selected;
    }
    return this.selectionModel.selected[0];
  }

  protected get displayValue() {
    if (this.displayWith && this.value) {
      if (Array.isArray(this.value)) {
        return this.value.length > 1 ? `${this.label}: ${this.value.length}` : this.displayWith(this.value[0]);
      }
      return this.displayWith(this.value);
    }
    return this.value;
  }

  @HostListener('blur')
  markAsTouched() {
    if (!this.disabled && !this.isOpen()) {
      this.onTouched();
    }
  }

  @HostListener('keydown', ['$event'])
  protected onKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowDown' && !this.isOpen()) {
      this.open();
      return;
    }
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.isOpen()) {
      this.listKeyManager.onKeydown(e);
      return;
    }
    if (e.key === 'Enter' && this.isOpen() && this.listKeyManager.activeItem) {
      this.handleSelection(this.listKeyManager.activeItem);
    }
  }

  @HostListener('click')
  open() {
    if (this.disabled) return;
    this.isOpen.set(true);
  }

  close() {
    this.isOpen.set(false);
    this.onTouched();
    this.hostEl.nativeElement.focus();
  }

  writeValue(value: SelectValue<T>): void {
    this.setupValue(value);
    this.highlightSelectedOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['compareWith']) {
      this.selectionModel.compareWith = changes['compareWith'].currentValue;
      this.highlightSelectedOptions();
    }
  }

  ngAfterContentInit(): void {
    this.listKeyManager = new ActiveDescendantKeyManager(this.options).withWrap();
    this.listKeyManager.change.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(itemIndex => {
      this.options.get(itemIndex)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    });
    this.selectionModel.changed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(values => {
      values.removed.forEach(rv => this.optionMap.get(rv)?.deselect());
      values.added.forEach(av => this.optionMap.get(av)?.highlightAsSelected());
    })
    this.options.changes.pipe(
      startWith<QueryList<OptionComponent<T>>>(this.options),
      tap(() => this.refreshOptionsMap()),
      tap(() => queueMicrotask(() => this.highlightSelectedOptions())),
      switchMap(options => merge(...options.map(o => o.selected))),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(selectedOption => this.handleSelection(selectedOption));
  }

  clearSelection(e?: Event) {
    e?.stopPropagation();
    if (this.disabled) return;
    this.selectionModel.clear();
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
  }

  protected onHandleInput(e: Event) {
    this.searchChanged.emit((e.target as HTMLInputElement).value);
  }

  private setupValue(value: SelectValue<T>) {
    this.selectionModel.clear();
    if (value) {
      if (Array.isArray(value)) {
        this.selectionModel.select(...value);
      } else {
        this.selectionModel.select(value);
      }
    }
  }

  private handleSelection(option: OptionComponent<T>) {
    if (this.disabled) return;
    if(option.onlySelectedItem()) {
      this.selectionModel.clear();
    }
    if (option.value) {
      this.selectionModel.toggle(option.value);
      this.selectionChanged.emit(this.value);
      this.optionMap.forEach((o, v) => {
        // remove focus from all other options
        if (v !== option.value) {
          o.setInactiveStyles();
        }
      });
      this.onChange(this.value);
    }
    if (!this.selectionModel.isMultipleSelection()) {
      this.close();
    }
    // Reset the onlySelectedItem flag
    option.onlySelectedItem.set(false);
  }

  private refreshOptionsMap() {
    this.optionMap.clear();
    this.options.forEach(o => this.optionMap.set(o.value, o));
  }

  private highlightSelectedOptions() {
    const valuesWithUpdatedReferences = this.selectionModel.selected.map(value => {
      const correspondingOption = this.findOptionsByValue(value)
      return correspondingOption ? correspondingOption.value! : value;
    });
    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
  }

  private findOptionsByValue(value: T | null) {
    if (this.optionMap.has(value)) {
      return this.optionMap.get(value);
    }
    return this.options && this.options.find(o => this.compareWith(o.value, value));
  }
}
