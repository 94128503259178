import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() { }

  private openDatabase(dbName: string, store: string, dbVersion = 2): Promise<IDBDatabase> {
    const request = indexedDB.open(dbName, dbVersion);
    // Return a promise that resolves when the database is ready
    return new Promise((resolve, reject) => {
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        // Create an object store if it doesn't exist
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store);
        }
      };
      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };
      request.onerror = (event) => {
        console.error('Error opening IndexedDB:', event);
        reject(event);
      };
    });
  }

  async idbAddOne(dbName: string, storeName: string, key: string, value: any): Promise<void> {
    const db = await this.openDatabase(dbName, storeName);
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    store.add(value, key);
  }

  async idbDeleteOne(dbName: string, storeName: string, key: string): Promise<void> {
    const db = await this.openDatabase(dbName, storeName);
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    store.delete(key);
  }

  async idbGetAll(name: string, store: string): Promise<any> {
    const db = await this.openDatabase(name, store);
    const transaction = db.transaction([store], 'readonly');
    const storeObj = transaction.objectStore(store);
    const data = storeObj.getAll();

    return new Promise((resolve, reject) => {
      data.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      data.onerror = (event) => {
        console.error('Error getting all from IndexedDB:', event);
        reject(event);
      };
    });
  }
}
