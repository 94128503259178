import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Activity, selectUnread } from '../store';
import * as fromActivities from '../store/activity.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState, selectIsReportingV2 } from 'src/app/store';

@Component({
  selector: 'app-activity-icon',
  templateUrl: './activity-icon.component.html'
})
export class ActivityIconComponent implements OnInit {
  notifications$: Observable<Activity[]>;
  isReportingV2$: Observable<boolean>;
  hidden = false;
  @Input() orgId: string;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.notifications$ = this.store.select(selectUnread)?.pipe(
      tap((unread) => {
        const orgId = unread[0]?.data?.orgId;
        if (orgId === this.orgId) {
          this.store.dispatch(fromActivities.saveUnreadNotifications({ unread, orgId }));
        }
      })
    );
    this.isReportingV2$ = this.store.select(selectIsReportingV2);
  }

  toggleBadgeVisibility(unread: Activity[]) {
    this.store.dispatch(fromActivities.toggleSidebar({orgId: this.orgId}));
    this.store.dispatch(fromActivities.markAsRead({ unread, isRead: true }));
  }
}
