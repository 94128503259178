import { ScenarioEffects } from './scenario.effects';
import { ProductEffects } from './product.effects';
import { UserEffects, OrganisationEffects, UserManagementEffects } from '../../views/user-management/redux/effects';
import { ActivityEffects }from '../../core/activity/store/activity.effects';
import { AnalyticsEffects } from './analytics.effects';
import { PageEffects } from './page.effects';
import { ReportingEffects } from './reporting.effects';
import { ScenarioTabsEffects } from "./scenario-tabs.effects";

export const effects: any[] = [ProductEffects, ScenarioEffects, UserManagementEffects, UserEffects, OrganisationEffects, ActivityEffects, AnalyticsEffects, PageEffects, ReportingEffects, ScenarioTabsEffects];

export * from './scenario.effects';
export * from './product.effects';
export * from './page.effects';
export * from '../../views/user-management/redux/effects';
export * from '../../core/activity/store/activity.reducer';
export * from './reporting.effects';
