@if (user$ | async; as user) {
  <div class="flex items-center" [ngClass]="{'gap-5' : !toggleUI, 'gap-2' : toggleUI}">
    <app-organisation-menu class="flex-1"
      [userOrganisations]="user.organisations"
      [currentOrganisation]="user.currentOrganisation"
      [isNewUI]="toggleUI"
      (switchOrganisation)="goToOrganisation($event, user, user.currentOrganisation?.id)">
    </app-organisation-menu>
    <app-user-menu
      class="relative " [ngClass]="{'block  w-8 h-8' : !toggleUI, 'flex' : toggleUI}"
      [user]="user"
      [currentOrganisation]="user.currentOrganisation"
      [toggleInternal]="toggleInternal"
      [isNewUI]="toggleUI"
      (toggleInternalMode)="toggleInternalMode($event)"
      (toggleUI)="switchUI($event)"
      (logout)="logout()">
    </app-user-menu>
  </div>
}