import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const ScenarioTabsActions = createActionGroup({
  source: 'ScenarioTabs/API',
  events: {
    'Load ScenarioTabs': emptyProps(),
    'Add ScenarioTab': props<{ scenarioTab: number, stopNavigation?: boolean }>(),
    'Upsert ScenarioTab': props<{ scenarioTab: number }>(),
    'Add ScenarioTabs': props<{ scenarioTabs: number[], activeTab?: number }>(),
    'Upsert ScenarioTabs': props<{ scenarioTabs: number[], activeTab?: number }>(),
    'Update ScenarioTab': props<{ scenarioTabs: Update<number> }>(),
    'Update ScenarioTabs': props<{ scenarioTabs: Update<number>[] }>(),
    'Delete ScenarioTab': props<{ id: number }>(),
    'Delete ScenarioTabs': props<{ ids: number[] }>(),
    'Clear ScenarioTabs': emptyProps(),
    'Set Active Tab': props<{ id: number }>(),
  }
});
