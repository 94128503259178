import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toggleSidebar } from '../activity/store';
import { Store } from '@ngrx/store';
import { SCENARIO_REPORT_LINKS } from 'src/app/views/scenario/scenario-side-menu/constants';
import { AppState, loadMoreOrganisations, loadMoreUsers, selectAllProducts, selectAllScenarios, selectIsReportingV2, selectOrganisations, selectUsers } from 'src/app/store';
import { PRODUCT_REPORT_LINKS } from 'src/app/views/product/product-side-menu/constants';
import { map, Observable, filter, switchMap, combineLatest } from 'rxjs';
import { IProduct } from 'src/app/views/product/model/product.model';
import { BreadcrumbService } from '../breadcrumbs/breadcrumbs.service';
import { IScenario } from 'src/app/views/scenario/model/scenario.model';
import { IOrganisation } from 'src/app/views/user-management/model/user-management.model';
import { DEFAULT_CURRENT_PAGE } from '../app.constants';
import { IUserState } from 'src/app/views/user-management/redux/reducers';
import { Dashboard } from "../../store/reducers/reporting.reducer";
import { selectAllReports } from "../../store/selectors/reporting.selectors";
import { REPORT_LINKS } from 'src/app/views/product/beta/product-dashboard-sidenav/constants';

interface IMenu {
  label: string;
  menuItems: any[];
  productId?: string;
  orgId?: string;
  total?: any;
  orgRoute?: any;
}

interface IBreadcrumbsMenu {
  products?: IMenu;
  scenarios?: IMenu;
  reports?: IMenu;
  users?: IMenu;
  organisations?: IMenu;
}

interface ICurrentRouteConfig {
  breadcrumb: string;
  breadcrumbs: string[];
  isScenarioRoute?: boolean;
  isUserManagementRoute?: boolean;
}

@Component({
  selector: 'app-breadcrumbs-menu',
  templateUrl: './breadcrumbs-menu.component.html',
  styleUrls: ['./breadcrumbs-menu.component.scss']
})
export class BreadcrumbsMenuComponent implements OnInit {

  public scenarioReportRoutes = SCENARIO_REPORT_LINKS.flatMap(({ links }) => links);
  public breadcrumbsMenu$: Observable<IBreadcrumbsMenu>
  public parentRoute: any;

  @Input() showScenarioListSelector: boolean;
  @Input() scenario: IScenario;
  @Input() product: IProduct;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private readonly breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.parentRoute = this.activatedRoute.parent;
    this.breadcrumbsMenu$ = this.breadcrumbService.url$.pipe(
      map((url: string) => this.getUrlSegments(url)),
      switchMap((breadcrumbs: string[]) => {
        return !!this.product?.id ? this.buildProductMenu(breadcrumbs) : this.buildOrgsMenu(breadcrumbs)
      })
    )
  }

  buildProductMenu(breadcrumbs: string[]): Observable<IBreadcrumbsMenu> {
    const getIdFromBreadcrumbs = (breadcrumb: string) => breadcrumbs[breadcrumbs.indexOf(breadcrumb) + 1];
    const showReportsDropdown = breadcrumbs.includes('reports') && !!getIdFromBreadcrumbs('reports');
    const isScenarioRoute = breadcrumbs.includes('scenarios') && !!this.scenario;
    const productId = getIdFromBreadcrumbs('products');
    const scenarioId = getIdFromBreadcrumbs('scenarios');
    return combineLatest([
      this.getProducts$(),
      this.getScenarios$(isScenarioRoute),
      this.store.select(selectIsReportingV2)
    ]).pipe(
      map(([products, scenarios, isReportsV2]) => {

        const reports =  showReportsDropdown ? (isScenarioRoute ? this.scenarioReportRoutes : this.getProductReportsRoutes(isReportsV2)) : null;
        return {
          products: {
            label: this.getLabelByRouteId(+productId, products) ?? '',
            menuItems: this.setMenuItems(products, { breadcrumb: 'products', breadcrumbs, isScenarioRoute: !isScenarioRoute })
          },
          ...(isScenarioRoute && {
            scenarios: {
              label: this.getLabelByRouteId(+scenarioId, scenarios),
              productId,
              menuItems: this.setMenuItems(scenarios, { breadcrumb: 'scenarios', breadcrumbs, isScenarioRoute })
            }
          }),
          ...(showReportsDropdown && {
            reports: {
              label: this.getReportLabel(reports, breadcrumbs),
              menuItems: reports?.map(({ label, link, role }) => ({ label, link, role })) ?? []
            }
          }),
        }
      })
   )}

  buildOrgsMenu(breadcrumbs: string[]): Observable<IBreadcrumbsMenu> {
    const getIdFromBreadcrumbs = (breadcrumb: string) => {
      const index = breadcrumbs.indexOf(breadcrumb);
      if (index === -1) return null;
      return breadcrumbs[breadcrumbs.indexOf(breadcrumb) + 1]
    };
    const orgId = getIdFromBreadcrumbs('organisations') ?? getIdFromBreadcrumbs('company-management');
    const userId = getIdFromBreadcrumbs('users')?.replace('%7C','|');
    const customDashboardId = getIdFromBreadcrumbs('custom-dashboard')?.replace('%7C','|');
    const isOrgRoute = breadcrumbs.includes('organisations') && getIdFromBreadcrumbs('organisations');
    const isUserRoute = breadcrumbs.includes('users') && !!userId;
    const isCustomDashboardRoute = breadcrumbs.includes('custom-dashboard');
    return combineLatest([
      this.getOrgs$(),
      this.getUsers$(isUserRoute),
      this.getCustomDashboards$()
    ]).pipe(
      map(([orgs, users, customDashboards  ]) => {
        return ((isOrgRoute || isUserRoute || isCustomDashboardRoute) && {
          ...(isOrgRoute && {
            organisations: {
              label: this.getLabelByRouteId(orgId, orgs.results) ?? '',
              orgId,
              menuItems: this.setMenuItems(orgs.results, { breadcrumb: 'organisations', breadcrumbs, isUserManagementRoute: true}),
              total: orgs.total
            },
          }),
          ...(isUserRoute && {
            users: {
              label: this.getLabelByRouteId(userId, users.users),
              orgRoute: isOrgRoute ? 'organisations/' + orgId : './',
              menuItems: this.setMenuItems(users.users, { breadcrumb: 'users', breadcrumbs, isUserManagementRoute: true }),
              orgId,
              total: users.totalCount
            }
          }),
           ...(isCustomDashboardRoute && {
            reports: {
              label: this.getCustomDashboardLabel(customDashboardId, customDashboards),
              menuItems: customDashboards?.map(({ title, id }) => ({ title, id })) ?? []
            }
          }),
        })
      })
   )}

  getProducts$(): Observable<IProduct[]> {
    return this.store.select(selectAllProducts).pipe(
      filter((products) => typeof products !== 'undefined'),
    )
  }

  getProductReportsRoutes(isV2: boolean): any[] {
    return isV2 ? 
      REPORT_LINKS.flatMap((report) => (report.hasDropdown ? report.dropdownItems : report)) :
      PRODUCT_REPORT_LINKS.flatMap(({ links }) => links);
  }

  getCustomDashboards$(): Observable<Dashboard[]> {
    return this.store.select(selectAllReports)
  }

  getScenarios$(isScenarioRoute: boolean): Observable<IScenario[]> {
    return this.store.select(selectAllScenarios).pipe(
      filter((scenarios) => typeof scenarios !== 'undefined'),
      map((scenarios) => isScenarioRoute ? scenarios : null)
    )
  }

  getOrgs$(): Observable<{results: IOrganisation[]; total: number}> {
    return this.store.select(selectOrganisations)
  }

  getUsers$(isUserRoute: boolean): Observable<{users: IUserState[]; totalCount: number}> {
    return this.store.select(selectUsers).pipe(
      map((users) => isUserRoute ? users : {
        users: [],
        totalCount: 0
      })
    )
  }

  getLabelByRouteId(routeId: string | number, list: any[]): string {
    return list?.find(({id}) => routeId === id)?.name;
  }

  getReportLabel(reports: any[], breadcrumbs: string[]) {
    const report = breadcrumbs[breadcrumbs.indexOf('reports') + 1];
    return reports.find(({link}) => link === report)?.label;
  }

  getCustomDashboardLabel(dashboardId: string, list: Dashboard[]) {
   return list?.find(({id}) => dashboardId === id.toString())?.title;
  }

  getUrlSegments(url: string): string[] {
    return url.split('?')[0].split('/').filter(item => item !== '');
  }

  setMenuItems(list: any[], config: ICurrentRouteConfig) {
   return list?.map(({name, id}) => ({name, link: this.getCurrentRoute(id, config)}))
  }

  getCurrentRoute(id: number | string, config: ICurrentRouteConfig) {
    const { breadcrumb, breadcrumbs, isScenarioRoute, isUserManagementRoute } = config;

    if(typeof isUserManagementRoute && typeof id === 'string') {
      const clone = [...breadcrumbs];
      clone[clone.indexOf(breadcrumb) + 1] = id;
      return `/${clone.slice(0, (clone.indexOf(breadcrumb) + 2)).join('/')}`;
    }

    if(isScenarioRoute) {
      breadcrumbs[breadcrumbs.indexOf(breadcrumb) + 1] = id.toString();
      return `/${breadcrumbs.join('/')}`;
    }
    return `/${breadcrumb}/${id}`;
  }

  toggleActivityFeed() {
    this.store.dispatch(toggleSidebar({ orgId: null }));
  }

  loadMoreOrgs(pageSize: number) {
    this.store.dispatch(loadMoreOrganisations({paginationParams: {page: DEFAULT_CURRENT_PAGE, pageSize}}))
  }

  loadMoreUsers(orgId: string, pageSize: number) {
    this.store.dispatch(loadMoreUsers({orgId, paginationParams: {page: DEFAULT_CURRENT_PAGE, pageSize}}));
  }
}
