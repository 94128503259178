import { CdkConnectedOverlay, ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, signal, ViewChild } from '@angular/core';
import { firstValueFrom, map, timer } from 'rxjs';
import { SvgImagesComponent } from '../svg-images/svg-images.component';

@Component({
  selector: 'app-overlay-popup',
  templateUrl: './overlay-popup.component.html',
  standalone: true,
  imports: [OverlayModule, SvgImagesComponent],
  styles: `:host { display: inline-flex; }`,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverlayPopupComponent {
  @Input() label: string = '';
  @Input() enableClick: boolean = false;
  @Input() position: 'top-center' | 'bottom-center' | 'left-center' | 'right-center' | null = null;
  @Input() isCustom: boolean = false;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(CdkConnectedOverlay) overlayContent: CdkConnectedOverlay;

  private viewportRuler = inject(ViewportRuler);
  private closeDelay = 100;
  private isHoveringContent = signal(false);
  private isHoveringMenu = signal(false);
  public isOpenState = signal(false);

  public toolTipPosition: ConnectedPosition[] = [
    { // top center
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    },
    { // bottom center
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    },
    { // top right
      originX: 'end', 
      originY: 'top',
      overlayX: 'end', 
      overlayY: 'bottom' 
    },
    { // bottom right
      originX: 'end', 
      originY: 'bottom', 
      overlayX: 'end', 
      overlayY: 'top' 
    }
  ];

  public popupPosition: ConnectedPosition[] = [
    { // right center
      originX: 'end', 
      originY: 'center',
      overlayX: 'start', 
      overlayY: 'center',
      offsetX: 10
    },    
    { // left center
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -10
    },
    { // right top
      originX: 'end', 
      originY: 'top',
      overlayX: 'start', 
      overlayY: 'top' 
    }, 
    { // right bottom
      originX: 'end', 
      originY: 'bottom',
      overlayX: 'start', 
      overlayY: 'bottom' 
    }, 
    { // left top
      originX: 'start', 
      originY: 'top',
      overlayX: 'end', 
      overlayY: 'top' 
    }, 
    { // left bottom
      originX: 'start', 
      originY: 'bottom',
      overlayX: 'start', 
      overlayY: 'top' 
    }
  ];
   
  async toggleNavGroup(isHovering: boolean): Promise<void> {
    if (this.enableClick) return;
    if (isHovering) {
      this.isHoveringMenu.set(true);
      this.openMenu();
      return;
    } 

      const close = await firstValueFrom(timer(this.closeDelay).pipe(
        map(() => !this.isHoveringContent() && !isHovering)
      ))
      if (close) {
        this.closeMenu();
      }
  }

  openMenu(): void {
    this.isOpenState.set(true);
  }

  closeMenu(): void {
    this.isOpenState.set(false);
    this.closed.emit(true);
  }

  toggleGroup() {
    this.isOpenState.set(!this.isOpenState());
   }

  onMouseEnterMenu(): void {
    if (this.enableClick) return;
    this.isHoveringContent.set(true);
    this.isHoveringMenu.set(false);
    this.openMenu();
  }

  async onMouseLeaveMenu(): Promise<void> {
    if (this.enableClick) return;
    this.isHoveringContent.set(false);
    const close = await firstValueFrom(timer(this.closeDelay).pipe(
      map(() => !this.isHoveringMenu())
    ))

    if (close) {
      this.closeMenu();
    }
  }

  protected handleResize($event: ResizeObserverEntry): void {
    // if popup is not in the viewport, update the position
    const { clientHeight, offsetTop } = this.overlayContent?.overlayRef?.overlayElement;
    const { bottom: viewportBottom } = this.viewportRuler.getViewportRect();

    if ((clientHeight + offsetTop) > viewportBottom) {
      this.overlayContent?.overlayRef?.updatePosition();
    }
  }

  protected getPosition(position: string | null) {
    if (!position?.length) {
      return this.label?.length > 0 ? this.toolTipPosition : this.popupPosition;
    }
    const setPosition = {
      'top-center': {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: 10
      },
      'bottom-center': {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
      },
      'left-center': {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center'
      },
      'right-center': {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center'
      },
    }[position] as ConnectedPosition;

    return [setPosition]
  }
}
