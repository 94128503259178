<span class="flex items-center no-underline justify-between w-full gap-8 py-2.5 px-3 group-[.is-active]/itemLink:bg-soft-blue hover:bg-digital-blue/5 hover:text-digital-blue rounded-md">
  <span class="flex-1 flex justify-between">
    <ng-content></ng-content>
  </span>
  <span class="flex items-center">
    @if (userRole?.length > 0) {
      <app-badge class="mr-1"> {{ userRole | uppercase }}</app-badge>
    }
    <app-svg-images svgName="arrow-small-right" class="h-3 opacity-0 group-hover/itemLink:opacity-100 group-[.is-active]/itemLink:hidden transition-all"></app-svg-images>
    <app-svg-images svgName="check" class="h-3 hidden group-[.is-active]/itemLink:inline-block"></app-svg-images>
  </span>
</span>