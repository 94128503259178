export const REPORT_LINKS = [
  {
    label: 'titles.product.reports.overview.title',
    icon: null,
    link: 'overview',
    role: null,
    hasDropdown: false,
    isFavourite: false,
    dropdownItems: null,
  },
  {
    label: 'titles.reports.categories.general',
    icon: 'chart-bar-solid',
    hasDropdown: true,
    dropdownItems: [
      {
        label: 'titles.product.reports.all.title',
        link: 'all',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.config-summary.title',
        link: 'config-summary',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.customer-facing-metrics.title',
        link: 'customer-facing-metrics',
        role: 'INTERNAL',
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.ltv-prediction-accuracy.title',
        link: 'ltv-prediction-accuracy',
        role: 'INTERNAL',
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.scenario-history.title',
        link: 'scenario-history',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
    ],
  },
  {
    label: 'titles.reports.categories.userAcquisition',
    icon: 'user-plus-solid',
    hasDropdown: true,
    dropdownItems: [
      {
        label: 'titles.product.reports.new-users.title',
        link: 'new-users',
        role: null,
        isFavourite: true,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.marketing-spend.title',
        link: 'marketing-spend',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.cost-per-acquisition.title',
        link: 'cost-per-acquisition',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.roas.title',
        link: 'roas',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.roas-history.title',
        link: 'roas-history',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.payback.title',
        link: 'payback',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.paid-percentage.title',
        link: 'paid-percentage',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.spend-recommendations.title',
        link: 'spend-recommendations',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
    ],
  },
  {
    label: 'titles.reports.categories.retention',
    icon: 'user-group-solid',
    hasDropdown: true,
    dropdownItems: [
      {
        label: 'titles.product.reports.daily-active-users.title',
        link: 'daily-active-users',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.retention-curve.title',
        link: 'retention-curve',
        role: null,
        isFavourite: true,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.retention-trends.title',
        link: 'retention-trends',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.retention-cohort.title',
        link: 'retention-cohort',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.active_users_layer_cake.title',
        link: 'active_users_layer_cake',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
    ],
  },
  {
    label: 'titles.reports.categories.monitisation',
    icon: 'currency-dollar-solid',
    hasDropdown: true,
    dropdownItems: [
      {
        label: 'titles.product.reports.user-revenue.title',
        link: 'user-revenue',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.cohort-monetisation.title',
        link: 'cohort-monetisation',
        role: null,
        isFavourite: true,
        isScenario: true,
      },
    ],
  },
  {
    label: 'titles.reports.categories.revenueAndLTV',
    icon: 'banknotes-solid',
    hasDropdown: true,
    dropdownItems: [
      {
        label: 'titles.product.reports.lifetime-value.title',
        link: 'lifetime-value',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.cumulative-lifetime-value.title',
        link: 'cumulative-lifetime-value',
        role: null,
        isFavourite: true,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.ltv-ratio.title',
        link: 'ltv-ratio',
        role: null,
        isFavourite: false,
        isScenario: true,
      },
      {
        label: 'titles.product.reports.revenue.title',
        link: 'revenue',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
      {
        label: 'titles.product.reports.product-revenue-summary.title',
        link: 'product-revenue-summary',
        role: null,
        isFavourite: false,
        isScenario: false,
      },
    ],
  },
];
