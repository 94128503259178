import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { USER_ROLE_LOCAL_STORAGE_KEY } from 'src/app/core/app.constants';
import { IPagination, IPaginatedDto } from 'src/app/core/model/app.model';
import { HelpersService } from 'src/app/core/services/helpers.service';
import {
  IUser,
  IOrganisation,
  IRole,
  CreateOrganisationUserInvitation,
  UpdateUserProfile,
  UpdateUserRole,
  UpdateOrganisation,
  UserDetails
} from '../model/user-management.model';
import { GraphqlService } from '../../../graphql/graphql.service';
import {
  INIT_APP_QUERY,
  ORGANISATION_QUERY,
  ORGANISATIONS_QUERY,
  ROLES_QUERY,
  ORGANISATION_USERS_QUERY,
  USER_QUERY,
  USER_ORGANISATIONS_QUERY
} from '../graphql/queries';
import { GraphQLParams, IGraphQLParams } from '../../../graphql/graphql.models';
import { CREATE_ORG_MUTATION } from '../graphql/mutations';
import { TranslateService } from '@ngx-translate/core';

interface IAppData {
  user: IUser;
  userOrganisations: IPaginatedDto<IOrganisation>;
  roles?: IPaginatedDto<IRole>;
  organisations?: IPaginatedDto<IOrganisation>;
}
@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends GraphqlService {
  private translateService = inject(TranslateService);
  private rolesSubject = new BehaviorSubject<IPaginatedDto<IRole>>(null);

  get resourceUrl() {
    return this.environment?.resourceServer.runwayUrl;
  }

  constructor(
    private http: HttpClient,
    private helpersService: HelpersService) {
    super();
  }

  initialiseApp(userId: string, isNormalUser = true, isSuperAdmin = false): Observable<IAppData> {
    const {page, pageSize, order, orderBy} = {order: 'asc', orderBy: 'name', pageSize: 100, page: 1}
    const sort = [orderBy, order].join('_').toUpperCase();

    return this.apollo.query<any>({
      fetchPolicy: 'network-only',
      query: INIT_APP_QUERY,
      variables: {
        userId,
        page,
        pageSize,
        sort,
        isNormalUser,
        isSuperAdmin
      }
    }).pipe(
      map(response => {
        if(response.errors) {
          const errorMessages: string = [...new Set(response.errors.map(err => err.message))].join('.');
          throw new Error(errorMessages);
        }

        const {user, roles, organisations} = response.data;
        const data = {
          user,
          userOrganisations: GraphQLParams.toIPaginatedDto<IOrganisation>(response, 'userOrganisations'),
          ...(roles && {roles: GraphQLParams.toIPaginatedDto<IRole>(response, 'roles')}),
          ...(organisations && {organisations: GraphQLParams.toIPaginatedDto<IOrganisation>(response, 'organisations')})
        }
        if (data.roles) {
          this.rolesSubject.next(data.roles);
        }
        return data;
      }),
    );
  }

  getUsers(orgId: string, paginationParams?: IGraphQLParams): Observable<IPaginatedDto<IUser>> {
    const {page, pageSize, sort} =  {page: 1, pageSize: 20, sort: ['name', 'asc'], ...paginationParams}; 

    return this.apollo.query<any>({
      fetchPolicy: 'network-only',
      query: ORGANISATION_USERS_QUERY,
      variables: {
        orgId,
        page,
        pageSize,
        sort: sort.join('_').toUpperCase()
      }
    }).pipe(
      map(response => {
        if(response.errors) {
          const errorMessages: string = this.translateService.instant('userManagement.users.errors.failed.fetch');
          throw new Error(errorMessages);
        }
        const users = GraphQLParams.toIPaginatedDto<IUser>(response, 'organisationUsers');
        const results = users.results.map((user) => {
          const { id, invitationId, name, email } = user;
          return {...user, id: (id ?? invitationId), name: (name ?? email)};
        });
        return {results, total: users.total};
      })
    );
  }

  getUser(userId: string): Observable<IUser> {
    return this.apollo.query<any>({
      query: USER_QUERY,
      variables: {
        id: userId
      }
    }).pipe(
      map(response => response.data.user),
      tap(user => {
        // We need to save the user role in the browser for state management when the user refreshes the page and redux data is lost as a result
        this.helpersService.deleteFromLocalStorage(USER_ROLE_LOCAL_STORAGE_KEY);
        this.helpersService.saveToLocalStorage(USER_ROLE_LOCAL_STORAGE_KEY, user.role.name);
      })
    );
  }

  getOrganisations(paginationParams: IGraphQLParams = {}): Observable<IPaginatedDto<IOrganisation>> {
    const {pageSize, page} = {pageSize: 30, page: 1, ...paginationParams};
    return this.apollo.query<any>({
      fetchPolicy: 'network-only',
      query: ORGANISATIONS_QUERY, variables: {
        pageSize,
        page
      }
    }).pipe(
      map(response => GraphQLParams?.toIPaginatedDto<IOrganisation>(response, 'organisations'))
    );
  }

  getUserOrganisations(userId: string, paginationParams?: IGraphQLParams): Observable<IPaginatedDto<IOrganisation>> {
    const {page, pageSize, sort} = {sort: ['name', 'asc'], pageSize: 30, page: 1, ...paginationParams}; 

    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: USER_ORGANISATIONS_QUERY,
      variables: {
        id: userId,
        page,
        pageSize,
        sort: sort.join('_').toUpperCase()
      }
    }).pipe(
      map(response => GraphQLParams.toIPaginatedDto<IOrganisation>(response, 'userOrganisations'))
    );
  }

  getOrganisation(orgId: string): Observable<IOrganisation> {
    return this.apollo.query<any>({
      fetchPolicy: 'network-only', query: ORGANISATION_QUERY, variables: {orgId}}).pipe(
      map(response => response.data.organisation)
    );
  }

  getRoles(paginationParams?: IPagination): Observable<IPaginatedDto<IRole>> {
    const {page, pageSize} = paginationParams ?? {};
    return this.apollo.query<any>({
      fetchPolicy: 'network-only',
      query: ROLES_QUERY,
      variables: {
        page,
        pageSize
      }
    }).pipe(
      map(response => GraphQLParams.toIPaginatedDto<IRole>(response, 'roles'))
    );
  }


  createOrganisation(name: string): Observable<IOrganisation> {
    return this.apollo.mutate<any>({
      mutation: CREATE_ORG_MUTATION,
      variables: {
        name
      }
    }).pipe(
      map(response => response.data.createOrganisation.organisation),
    )
  }

  addOrganisationUser(orgId: string, user: CreateOrganisationUserInvitation): Observable<HttpResponse<CreateOrganisationUserInvitation>> {
    return this.http.post<HttpResponse<CreateOrganisationUserInvitation>>(`${this.resourceUrl}organisations/${orgId}/users`, user);
  }

  deleteOrganisationUser(orgId: string, userId: string, list = UserDetails.USER_LIST): Observable<IUser> {
    return this.http.delete<IUser>(`${this.resourceUrl}organisations/${orgId}/${list}/${userId}`);
  }

  updateUserProfile(userId: string, user: UpdateUserProfile): Observable<UpdateUserProfile> {
    return this.http.put<UpdateUserProfile>(`${this.resourceUrl}users/${userId}`, {...user});
  }

  resetUserPassword(userId: string): Observable<string> {
    return this.http.post<string>(`${this.resourceUrl}users/${userId}/password-reset`, userId);
  }

  updateUserRole(orgId: string, userId: string, role: UpdateUserRole): Observable<IRole> {
    return this.http.put<UpdateUserRole>(`${this.resourceUrl}organisations/${orgId}/users/${userId}/roles`, role).pipe(
      map(role => {
        return this.rolesSubject.value.results.find(r => r.id === role.id)
      })
    );
  }

  updateOrganisation(orgId: string, organisation: UpdateOrganisation): Observable<UpdateOrganisation> {
    return this.http.put<UpdateOrganisation>(`${this.resourceUrl}organisations/${orgId}`, organisation);
  }

  uploadImage(id: string, imageFile: File, operation: string, mutation: any): Observable<any> {
    return this.apollo.mutate<any>({
      mutation,
      variables: {
        imageFile,
        id
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      map(response => response.data[operation]),
    )
  }

  deleteImage(id: string, operation: string, mutation: any): Observable<any> {
    return this.apollo.mutate<any>({
      mutation,
      variables: {
        id
      }
    }).pipe(
      map(response => response.data[operation]),
    )
  }
}
