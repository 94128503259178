<div class="relative group/section-switcher">
  <button
    class="group/route active flex items-center gap-3.5 text-sm group-hover/section-switcher:bg-soft-blue border border-transparent hover:border-gray-200/50 py-1.5 px-2.5 rounded-md transition-all group/button text-opacity-60 group-hover/section-switcher:text-black group-hover/section-switcher:text-opacity-100"
    (click)="redirect()">
    <span class="inline-flex items-center gap-2.5">
      <app-svg-images
        [ngClass]="{
            'group-hover/section-switcher:text-teal-green': isScenarioRoute,
            'group-hover/section-switcher:text-digital-blue': isProductRoute
        }"
        class="h-3.5 text-gray-400 transition-all"
        [svgName]="icon"></app-svg-images>
      <ng-content></ng-content>
      @if (hasDropdownList) {
        <span
          class="h-2.5 text-gray-400 group-hover/section-switcher:rotate-180 group-hover/section-switcher:text-gray-500 transition-all">
          <app-svg-images class="h-2.5" svgName="chevron-down"></app-svg-images>
        </span>
      }
    </span>
  </button>
  @if (hasDropdownList) {
    <div class="invisible group-hover/section-switcher:visible hover:visible absolute left-0 top-10 min-w-[350px] pt-1 z-50">
      <div class="rounded-lg border bg-white text-black py-1.5 px-2 shadow-sm transition-all w-full fade-in-down max-h-full dark:border-slate-900">
        <div class="pb-1">
          <ng-content select="[breadcrumbsBackToList]"></ng-content>
        </div>
        <div appDropdownScroll class="border-t pb-1 border-gray-100 space-y-1 pt-1 max-h-full overflow-auto">
          <ng-content select="[breadcrumbsDropdown]"></ng-content>
          @if (!isTotalItems) {
            <app-breadcrumbs-dropdown-item
              class="block bg-digital-blue/5 text-digital-blue rounded-md"
              appObserveElement
              #intersection="intersection"
              (isIntersecting)="isIntersecting($event)"
              [isContinuous]="true">
              <div class="w-full text-center animate-pulse">
                <span class="inline-block h-3 animate-spin">
                  <app-svg-images svgName="spinner-alt" class="h-4"></app-svg-images>
                </span>
                <span translate="global.loadingMore"></span>
              </div>
            </app-breadcrumbs-dropdown-item>
          }
        </div>
      </div>
    </div>
  }
</div>
