import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReportingActions = createActionGroup({
  source: 'Reporting',
  events: {
    'Load Reports': emptyProps(),
    'Load Reports Success': props<{ group: any[], personal: any[] }>(),
    'Load Reports Failure': props<{ error: unknown }>(),
    'Update Filters': props<{ filters: any }>(),
    'Reset Filters': emptyProps(),
    'Fetch Favourites': emptyProps(),
    'Fetch Favourites Success': props<{ list: any[] }>(),
    'Fetch Favourites Failure': props<{ error: unknown }>(),
    'Add Favourite': props<{ label: string, value: any }>(),
    'Add Favourite Success': props<{ report: {[key: string]: any} }>(),
    'Add Favourite Failure': props<{ error: unknown }>(),
    'Delete Favourite': props<{ label: string }>(),
    'Delete Favourite Success': props<{ label: string }>(),
    'Delete Favourite Failure': props<{ error: unknown }>()
  }
});
