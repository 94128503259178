import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromUserActions from '../actions/user.actions';
import * as fromUserManagementActions from '../actions/user-management.actions';
import * as PageActions from 'src/app/store/actions/page.actions';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../services/user-management.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userManagementService: UserManagementService,
    public messageService: AlertService,
    private translateService: TranslateService,
  ) {
  }

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.deleteUser),
      mergeMap(({orgId, userId, list}) =>
        this.userManagementService.deleteOrganisationUser(orgId, userId, list).pipe(
          map(({id}) => fromUserActions.deleteUserById({id})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagementActions.updateUserProfile),
      mergeMap(({userId, user}) =>
        this.userManagementService.updateUserProfile(userId, user).pipe(
          map((user) => fromUserActions.updateUser({user: {id: userId, changes: user}})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  resetUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserManagementActions.resetUserPassword),
      mergeMap(({userId}) =>
        this.userManagementService.resetUserPassword(userId).pipe(
          map((userId) => {
            this.messageService.success(
              this.translateService.instant('userManagement.notifications.changePassword.success'),
              this.translateService.instant('actions.dismiss')
            );
            return fromUserManagementActions.resetUserPasswordSuccess({ userId})
          }),
          catchError((error) => {
            this.messageService.error(
              this.translateService.instant('userManagement.notifications.changePassword.failed')
            );
            return of(PageActions.pageApiError({error}))
          })
        )
      )
    )
  );

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.fetchUsers),
      mergeMap(({orgId, paginationParams}) =>
        this.userManagementService.getUsers(orgId, paginationParams).pipe(
          map(({results, total}) => fromUserActions.loadUsers({users: results, totalCount: total})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  loadMoreUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.loadMoreUsers),
      mergeMap(({orgId, paginationParams}) =>
        this.userManagementService.getUsers(orgId, paginationParams).pipe(
          map(({results, total}) => fromUserActions.upsertUsers({users: results, totalCount: total})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  fetchUserOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.fetchUserOrganisations),
      mergeMap(({userId, paginationParams}) =>
        this.userManagementService.getUserOrganisations(userId, paginationParams).pipe(
          map((userOrganisations) => fromUserActions.updateUser({user: {id: userId, changes: {organisations: userOrganisations}}})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  updateUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.updateUserRole),
      mergeMap(({orgId, user, role}) =>
        this.userManagementService.updateUserRole(orgId, user.id, role).pipe(
          map((role) => fromUserActions.updateUser({ 
              user: { 
                id: user.id, 
                changes: {
                  ...user, 
                  organisations: {
                    results: user.organisations.results.map(org => org.id === orgId ? {...org, role} : org),
                    total: user.organisations.total
                  }
                } 
              }
            })),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  loadingFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PageActions.pageApiError),
      map(() => fromUserManagementActions.loadingFailed()),
    )
  });

  clearUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.fetchUsers),
      map(() => fromUserActions.clearUsers())
    )
  });
}
