import { gql } from 'apollo-angular';

export const scenarioMarketingOverrides = `
  marketingFatigueOverrides {
      productBlendedSegmentIds
      value
  }
  paidPercentageOverrides {
      productBlendedSegmentIds
      value
  }
  cpiSensitivityToSpendOverrides {
      productBlendedSegmentIds
      value
  }`
export const scenarioMarketingFields = `
      paybackMethod
      mappedPaybackMethod
      spendLookbackNumberOfDays
      spendLookbackRelative
      spendLookbackDate
      defaultPaidPercentage
      id
      mode
      scenarioId
      targetPayback
      defaultPaidPercentage
      defaultCpiSensitivityToSpend
      defaultMarketingFatigue
      overrideProductPaidPercentage
      roasTargetPercentage
      buyClv
      blendedSegmentsList {
        edges {
          node {
            segmentId
            paidPercentage
            segment {
              name
            }
          }
        }
      }
`

export const SCENARIO_MARKETING_QUERY = gql`
  query scenarioMarketingConfig($scenarioId: BigInt!) {
    scenarioMarketingConfig(scenarioId: $scenarioId) {
      ${scenarioMarketingFields}
      segmentCount
      blendedSegments{
        name
        id
      }
      ${scenarioMarketingOverrides}
      overrideSegmentsForPaid {
        id
        name
      }
      overrideSegmentsForPvc {
        id
        name
      }
    }
  }
`;

export const SCENARIO_MARKETING_MUTATION = `
  mutation updateMarketingConfig($scenarioId: Int, $scenarioMarketingConfig: ScenarioMarketingConfigInput!) {
    updateMarketingConfig(scenarioId: $scenarioId, scenarioMarketingConfig: $scenarioMarketingConfig) {
      scenarioMarketingConfig {
        ${scenarioMarketingFields}
        overrideSegmentsForPaid {
            id
            name
        }
        overrideSegmentsForPvc {
            id
            name
        }
        scenario {
            config
        }
      }
    }
  }
`

export const SCENARIO_MARKETING_PVC_PREVIEW_QUERY = `
    query scenarioMarketingPvcPreview($scenarioId: BigInt!, $segmentIds: [String], $cpiSensitivityToSpend: Float!, $marketingFatigue: Float!) {
        scenarioMarketingPvcPreview(scenarioId: $scenarioId, productBlendedSegmentIds: $segmentIds, cpiSensitivityToSpend: $cpiSensitivityToSpend, marketingFatigue: $marketingFatigue) {
            dnuBaseline
            dnuFatigue
            cpiBaseline
            cpiFatigue
            cpiActualLastYear
            cpiActualLookback
            dnuActualLastYear
            dnuActualLookback
            actualsAvg
            pvModel
        }
    }
`
