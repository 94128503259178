import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReporting from '../reducers/reporting.reducer';
import { selectAllScenarios } from "./scenario.selectors";
import { MarketingMode, ScenarioMarketingConfig } from "../../views/scenario/settings/models/config.models";
import { Helpers } from 'src/app/core/shared/helpers';
import { Scenario } from "../../views/scenario/model/scenario.model";
import { selectPageHeaderTitle } from './page.selectors';

export const selectReportingState = createFeatureSelector<fromReporting.ReportsState>(
  fromReporting.reportingFeatureKey
);

export const selectReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.reports
);

export const selectPersonalReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.personal
);

export const selectAllReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => [...state.reports, ...state.personal]
);

export const selectReport = (id: number) => createSelector(
  selectAllReports,
  (reports) => reports.find(report => report.id.toString() === id.toString())
);

export const loadingReportsState = createSelector(
  selectReportingState,
  (state) => state.loading
);

export const selectFilters = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.filters
);

export const selectFavouriteReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => {
    return new Map(state.favourites.map((favourite) => [favourite.label, favourite.label]));
  }
);

export const selectReportPageHasFavourite = createSelector(
  selectFavouriteReports,
  selectPageHeaderTitle,
  (favourites, pageTitle) => {
    const label = pageTitle?.includes('titles.') ? `${pageTitle}.title` : pageTitle;
    return favourites.has(label);
  }
);

export const selectScenarioById = (id: number) => createSelector(
  selectAllScenarios,
  (scenarios) => scenarios.find(scenario => scenario.id === id) ?? new Scenario(id),
);

/**
 * Custom filters per dashboard
 * @param dashboard
 * @param id
 */
export const selectFiltersByDashboardAndScenarioId = (dashboard: string, id: number) => createSelector(
    selectScenarioById(id),
    selectFilters,
    selectAllScenarios,
    (state, filters, scenarios) => {

      if (['scenario_non_daily', 'scenario'].includes(dashboard)) {
        return (id && {
          ...(state.config.marketingV2.mode === MarketingMode.TARGET_PAYBACK && {
            'LTV DX': state.config.marketingV2.targetPayback,
            'ROAS DX': [7, state.config.marketingV2.targetPayback, 365].sort((a, b) => a - b).join(',')
          }),
        })
      }

      if ('spend_recommendations' === dashboard) {
        return (id && {
          'Target Dx': ScenarioMarketingConfig.isTargetROAS(state.config.marketingV2.mode) ? 
            state.config.marketingV2.targetPayback : 365,
          'Actuals Lookback': state.config.marketingV2.spendLookback.numberOfDays
        })
      }

      if (['product_overview'].includes(dashboard)) {
        // select baseline and budget scenario
        const baseline = scenarios.find(scenario => scenario.default);
        const budget = scenarios.find(scenario => scenario.budget);
        return {
          'Baseline': baseline?.id,
          'Budget': budget?.id,
        }
      }

      if ('retention_curve_segmented' === dashboard) {
        return Helpers.getRetentionCurveFilter(state.config.inputRange.end);
      }

      return {}
    }
  )
;
