import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';
import { from, of, switchMap } from 'rxjs';
import { ReportingActions } from '../actions/reporting.actions';
import { LookerService } from '../../core/services/looker.service';
import * as fromAuth from "../../views/user-management/redux/actions";
import * as fromProduct from "../actions";
import { Action, Store } from "@ngrx/store";
import { WebStorageService } from 'src/app/core/services/web-storage.service';
import { concatLatestFrom } from '@ngrx/operators';
import { AppState } from '../reducers';
import { getAuthUser } from '../selectors';


@Injectable()
export class ReportingEffects implements OnInitEffects {
  private looker = inject(LookerService);
  private idb = inject(WebStorageService);
  private store$ = inject(Store<AppState>);
  private INDEXED_DB_NAME = 'reports-storage';
  private INDEXED_DB_STORE = 'favourites';

  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportingActions.loadReports, fromAuth.organisationChanged),
      switchMap(() =>
        this.looker.getCustomDashboards().pipe(
          map(({group, personal}) => ReportingActions.loadReportsSuccess({group, personal})),
          catchError(error => of(ReportingActions.loadReportsFailure({error}))))
      )
    );
  });

  fetchFavouriteReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportingActions.fetchFavourites, ReportingActions.addFavouriteSuccess),
      concatLatestFrom(() => this.store$.select(getAuthUser)),
      switchMap(([, authUser]) => {
        const {currentOrgId, id} = authUser;
        const dbName = `${this.INDEXED_DB_NAME}-${currentOrgId}-${id}`;
        return from(this.idb.idbGetAll(dbName, this.INDEXED_DB_STORE)).pipe(
          map(list => ReportingActions.fetchFavouritesSuccess({list})),
          catchError(error => of(ReportingActions.fetchFavouritesFailure({error})))
        )
      })
    );
  });

  addFavouriteReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportingActions.addFavourite),
      concatLatestFrom(() => this.store$.select(getAuthUser)),
      switchMap(([report, authUser]) => {
        const {label, value} = report;
        const {currentOrgId, id} = authUser;
        const dbName = `${this.INDEXED_DB_NAME}-${currentOrgId}-${id}`;
        return from(this.idb.idbAddOne(dbName, this.INDEXED_DB_STORE, label, value)).pipe(
          map(() => ReportingActions.addFavouriteSuccess({report})),
          catchError(error => of(ReportingActions.addFavouriteFailure({error})))
        )
      })
    );
  });

  removeFavouriteReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportingActions.deleteFavourite),
      concatLatestFrom(() => this.store$.select(getAuthUser)),
      switchMap(([report, authUser]) => {
        const {label} = report;
        const {currentOrgId, id} = authUser;
        const dbName = `${this.INDEXED_DB_NAME}-${currentOrgId}-${id}`;
        return from(this.idb.idbDeleteOne(dbName, this.INDEXED_DB_STORE, label)).pipe(
          map(() => ReportingActions.deleteFavouriteSuccess({label})),
          catchError(error => of(ReportingActions.deleteFavouriteFailure({error})))
        )
      })
    );
  });

  resetFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuth.organisationChanged, fromProduct.clearScenarios),
      map(() => ReportingActions.resetFilters())
    );
  })

  ngrxOnInitEffects(): Action {
    return ReportingActions.loadReports()
  }

  constructor(private actions$: Actions) {
  }
}
