import { inject, Injectable } from '@angular/core';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { ITokenProvider, TokenProviderResponse } from "@pusher/push-notifications-web";
import { lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConfigAwareService } from "../config/models/config";

@Injectable({
  providedIn: 'root'
})
export class BeamsService extends ConfigAwareService {

  beamsClient: PusherPushNotifications.Client;
  httpClient: HttpClient = inject(HttpClient);

  start(userId: string) {
    const vm = this;
    const beamsTokenProvider: ITokenProvider = {
      fetchToken(userId: string): Promise<TokenProviderResponse> {
        return lastValueFrom(
          vm.httpClient.get<TokenProviderResponse>(vm.environment.resourceServer.runwayUrl + 'beams/auth', {params: {userId}})
        )
      }
    }
    this.beamsClient = new PusherPushNotifications.Client({
      instanceId: vm.environment.pusher.beamsInstanceId,
    });

    this.beamsClient
      .start()
      .then(() => this.beamsClient.clearDeviceInterests())
      .then(() => this.beamsClient.setUserId(userId, beamsTokenProvider))
      .then(() => this.beamsClient.getDeviceId())
      .catch(console.error);
  }
}
