import { Action, createReducer, on } from '@ngrx/store';
import * as PageActions from '../actions/page.actions';

export interface PageState {
  pageTitle: string;
  IsReportingV2?: boolean;
}

export const initialState: PageState = {
  pageTitle: null,
  IsReportingV2: localStorage.getItem('UI_REPORTING_V2') ? JSON.parse(localStorage.getItem('UI_REPORTING_V2')).isV2 : false
};

export const pageReducer = createReducer(
  initialState,
  on(PageActions.setPageHeaderContent, (state, action) => {
    return {...state, ...action};
  })
);

export function reducer(state: PageState | undefined, action: Action) {
  return pageReducer(state, action);
}
