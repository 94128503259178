import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      #customButton
      class="px-3 w-full rounded-md active shadow-sm text-sm font-medium flex gap-1.5 items-center justify-center disabled:opacity-80"
      [ngClass]="{
        primary : 'bg-kohort-primary text-white hover:bg-opacity-90 disabled:bg-opacity-100 h-10',
        secondary : 'bg-kohort-secondary text-white hover:bg-opacity-90 disabled:bg-opacity-100 h-10',
        default : 'bg-white hover:bg-gray-50 disabled:hover:bg-white border border-gray-300 text-black h-10',
        tertiary : 'bg-digital-blue text-white px-4 py-2.5 rounded-lg active hover:bg-opacity-90 text-sm disabled:pointer-events-none disabled:opacity-50 h-10',
        custom : customClass
      }[variant]"
      [type]="type"
      [disabled]="disabled"
      (click)="handleClick()"
    >
      <ng-content></ng-content>
    </button>
  `,
  standalone: true,
  host: {
    '[attr.tabindex]': '-1',
    '(focus)': '_buttonElement.nativeElement.focus()',
  },
  imports: [CommonModule]
})
export class ButtonComponent {
  @Input() variant: 'primary' | 'secondary' | 'default' | 'tertiary' | 'custom' = 'default';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() disabled: boolean = false;
  @Input() customClass: string = '';
  @Output() onClick = new EventEmitter<void>();
  @ViewChild('customButton') _buttonElement: HTMLButtonElement;

  handleClick() {
    this.onClick.emit();
  }
}
