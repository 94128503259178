import { ActivatedRoute } from '@angular/router';
import { LookerResponse } from '../../services/looker.service';
import { Preset } from '../looker-granularity/looker-granularity.component';
import { DEFAULT_DATE_RANGES } from "../../app.constants";

export class BaseGranularity {

  url: LookerResponse = new LookerResponse();
  loaded = false;
  _dashboard = '';

  granularityOptions: Preset[] = [
    {
      label: 'Day',
      icon: 'chart-line',
      type: 'looker_line',
      filters: {Granularity: 'Day', ...DEFAULT_DATE_RANGES.Daily}
    },
    {
      label: 'Week',
      icon: 'chart-bar-solid',
      type: 'looker_column',
      filters: {Granularity: 'Week', ...DEFAULT_DATE_RANGES.Other}
    },
    {
      label: 'Month',
      icon: 'chart-bar-solid',
      type: 'looker_column',
      filters: {Granularity: 'Month', ...DEFAULT_DATE_RANGES.Other}
    },
    {
      label: 'Quarter',
      icon: 'chart-bar-solid',
      type: 'looker_column',
      filters: {Granularity: 'Quarter', ...DEFAULT_DATE_RANGES.Other}
    },
    {
      label: 'Year',
      icon: 'chart-bar-solid',
      type: 'looker_column',
      filters: {Granularity: 'Year', ...DEFAULT_DATE_RANGES.Other}
    },
  ];
  selectedPreset = this.granularityOptions[0];

  sharedHiddenFilters = ['Granularity'];
  scenarioHiddenFilters = [...this.sharedHiddenFilters, 'Scenario', 'LTV DX', 'ROAS DX' ];
  productHiddenFilters = [...this.sharedHiddenFilters, 'Product', 'Scenario'];
}
