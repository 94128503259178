import { createAction, props } from '@ngrx/store';
import { IOrganisation, IUser, UpdateUserProfile } from '../../model/user-management.model';
import { Update } from '@ngrx/entity';
import { IGraphQLParams } from 'src/app/graphql/graphql.models';

export const loadApp = createAction(
  '[User/API] Load Web App',
  props<{ userId: string, orgId: string, isNormalUser: boolean, isSuperAdmin: boolean }>()
);

export const loadAppSuccess = createAction(
  '[User/API] Load Web App Success',
  props<{ orgId?: string, data: any }>()
);

export const loadAppFailure = createAction(
  '[User] Load App Failure',
  props<{ error: any }>()
);

export const loadAuthUser = createAction(
  '[User] Load User',
  props<{ orgId?: string, userId: string }>()
);

export const organisationChanged = createAction(
  '[User] Organisation Changed',
);

export const toggleInternalData = createAction(
  '[User] Toggle Internal Data',
  props<{ paginationParams: IGraphQLParams }>()
);

export const loadAuthUserSuccess = createAction(
  '[User] Load User Success',
  props<{ orgId?: string, user: IUser }>()
);

export const loadAuthUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const updateUserProfile = createAction(
  '[User] Update User Profile',
  props<{ userId: string, user: UpdateUserProfile }>()
);

export const updateUserProfileSuccess = createAction(
  '[User] Update User Profile Success',
  props<{ user: UpdateUserProfile }>()
);

export const updateUserProfileFailure = createAction(
  '[User] Update User Profile Failure',
  props<{ error: any }>()
);

export const resetUserPassword = createAction(
  '[User] Reset User Password',
  props<{ userId: string }>()
);

export const resetUserPasswordSuccess = createAction(
  '[User] Reset User Password Success',
  props<{ userId: string }>()
);

export const resetUserPasswordFailure = createAction(
  '[User] Reset User Password Failure',
  props<{ error: any }>()
);

export const uploadUserImage = createAction(
  '[Image] Upload UserImage',
  props<{ id: string, file: File, operation: string }>()
);

export const uploadUserImageSuccess = createAction(
  '[Image] Upload UserImage Success',
  props<{ user: IUser }>()
);

export const uploadUserImageFailure = createAction(
  '[Image] Upload UserImage Failure',
  props<{ error: any }>()
);

export const deleteUserImage = createAction(
  '[Image] Delete UserImage',
  props<{ id: string, operation: string }>()
);

export const deleteUserImageSuccess = createAction(
  '[Image] Delete UserImage Success',
  props<{ user: IUser }>()
);

export const deleteUserImageFailure = createAction(
  '[Image] Delete UserImage Failure',
  props<{ error: any }>()
);

export const updateAuthUserOrganisation = createAction(
  '[User] Update Auth User Organisation',
  props<{ organisation: Update<IOrganisation> }>()
);

export const loadingFailed = createAction(
  '[User Management] Loading Failed'
);