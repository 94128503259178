{
  "name": "control",
  "version": "0.529.0",
  "scripts": {
    "start": "ng serve",
    "build": "ng build --aot",
    "build-dev": "ng build --aot --output-path=dist_dev",
    "build-local": "ng build --aot --configuration production --output-path=dist_dev",
    "test": "ng test --watch=false --code-coverage",
    "test-ci": "ng test --watch=false --code-coverage --browsers=ChromeHeadlessCustom",
    "watch:test": "ng test --watch=true",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "ngsw-config": "node_modules/.bin/ngsw-config dist ngsw-config.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "^17.3.3",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/forms": "^18.1.3",
    "@angular/material": "^17.3.3",
    "@angular/material-moment-adapter": "^17.3.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/pwa": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@angular/service-worker": "^18.1.3",
    "@apollo/client": "^3.9.10",
    "@auth0/auth0-angular": "^2.2.3",
    "@fullstory/browser": "^1.4.7",
    "@messageformat/core": "^3.0.1",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/schematics": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@pusher/push-notifications-web": "^1.1.0",
    "@segment/analytics-next": "^1.70.0",
    "@sentry/angular": "^8.7.0",
    "@uppy/compressor": "^1.1.4",
    "@uppy/core": "^3.13.1",
    "@uppy/dashboard": "^3.9.1",
    "@uppy/image-editor": "^2.4.6",
    "apollo-angular": "^7.0.2",
    "extract-files": "^13.0.0",
    "flag-icons": "^7.2.1",
    "graphql": "^16",
    "highcharts": "^11.4.3",
    "moment": "^2.29.1",
    "ngx-papaparse": "^8.0.0",
    "ngx-translate-messageformat-compiler": "^6.0.0",
    "puppeteer": "^22.15.0",
    "pusher-js": "^8.3.0",
    "rxjs": "^7.8.0",
    "tslib": "^2.3.1",
    "zone.js": "0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.1.3",
    "@angular/language-service": "^18.1.3",
    "@types/css-font-loading-module": "^0.0.7",
    "@types/jasmine": "~4.0.3",
    "@types/jasminewd2": "^2.0.8",
    "@types/node": "^12.11.1",
    "autoprefixer": "^10.4.16",
    "jasmine-core": "~4.3.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "karma-junit-reporter": "^2.0.1",
    "postcss": "^8.4.31",
    "tailwindcss": "^3.3.3",
    "ts-loader": "^9.5.1",
    "ts-node": "10.9.1",
    "typescript": "5.5.4"
  },
  "optionalDependencies": {
    "@rollup/rollup-linux-x64-gnu": "^4.24.0"
  },
  "engines": {
    "node": ">=14.17.0",
    "npm": ">=6.14.13"
  }
}
