import { NgSwitch } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-images',
  templateUrl: './svg-images.component.html',
  standalone: true,
  imports: [NgSwitch],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgImagesComponent {
  @Input() svgName: string = '';
}
