import { createFeatureSelector, createSelector } from "@ngrx/store";
import { scenarioTabsFeatureKey, selectAll, State } from "../reducers/scenario-tabs.reducer";
import {
  selectAllScenarios,
  selectFavouriteScenarios,
  selectScenarios,
  selectScenarioState
} from "./scenario.selectors";

export const scenarioTabsSelectors = createFeatureSelector<State>(
  scenarioTabsFeatureKey
);

export const selectAllTabs = createSelector(
  scenarioTabsSelectors,
  selectAll
);

export const selectAllNonFavoriteScenarios = createSelector(
  selectAllScenarios,
  selectFavouriteScenarios,
  (scenarios, favourites) => scenarios.filter(scenario => !favourites.some(favourite => favourite.id === scenario.id))
);

export const selectAllScenariosExcludingTabbed = createSelector(
  selectAllNonFavoriteScenarios,
  selectAllTabs,
  (scenarios, tabs) => scenarios.filter(scenario => !tabs.some(tab => tab === scenario.id))
);

export const selectFavouriteScenariosExcludingTabbed = createSelector(
  selectFavouriteScenarios,
  selectAllTabs,
  (scenarios, tabs) => scenarios.filter(scenario => !tabs.some(tab => tab === scenario.id))
);

export const selectAvailableScenarios = createSelector(
  selectAllScenarios,
  selectAllTabs,
  (scenarios, tabs) => scenarios.filter(scenario => !tabs.some(tab => tab === scenario.id))
);

export const selectAllScenarioTabs = createSelector(
  selectAllScenarios,
  selectAllTabs,
  (scenarios, tabs) => tabs.map(tab => scenarios.find(scenario => scenario.id === tab))
);

export const selectActiveTab = createSelector(
  scenarioTabsSelectors,
  selectAllTabs,
  (state, tabs) => {
    const index = tabs.findIndex(tab => tab === state.activeTab)
    return (index === -1) ? 0: index + 1
  }
);
