  <div class="relative group/menu" appPopper position="rightStart" closeDelay="350">
    <div data-app-popper="trigger">
      <ng-content></ng-content>
    </div>
    <div
      class="z-[100] transition-opacity opacity-0 fixed invisible" 
      [ngClass]="{'mt-[50px]' : isScenarioNavGroup}"
      data-app-popper="content">
      <div 
      appDropdownScroll
        [ngClass]="{
          'bg-white border border-gray-300 text-black shadow-lg rounded-xl min-w-[320px] fade-in px-3 py-1.5 text-sm font-medium ml-2': listCount > 0,
          '-mt-2': listCount > 1 && listCount <= 3,
          '-mt-6': listCount > 3
        }">
        <ng-content select="[navListGroupDropDownMenu]"></ng-content>
      </div>
    </div>
  </div>