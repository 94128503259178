import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromScenario from './scenario.reducer';
import * as fromProduct from './product.reducer';
import * as fromPage from './page.reducer';
import * as fromUserManagement from '../../views/user-management/redux/reducers';
import * as fromActivityFeed from '../../core/activity/store/activity.reducer';
import * as fromReporting from './reporting.reducer';
import * as fromTabs from './scenario-tabs.reducer';

export interface AppState {
  scenario: fromScenario.State;
  product: fromProduct.State;
  page: fromPage.PageState;
  userManagement: fromUserManagement.UserManagementState;
  activities: fromActivityFeed.ActivityState;
  reporting?: fromReporting.ReportsState,
  scenarioTabs?: fromTabs.State
}

export const reducers: ActionReducerMap<AppState> = {
  scenario: fromScenario.reducer,
  product: fromProduct.reducer,
  page: fromPage.reducer,
  userManagement: fromUserManagement.userManagementReducers,
  activities: fromActivityFeed.reducer,
  reporting: fromReporting.reducer,
  scenarioTabs: fromTabs.reducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
