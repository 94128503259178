@if (notifications$ | async; as notifications) {
  @if (isReportingV2$ | async) {
    <app-overlay-popup [label]="'app.navigation.notifications.tooltip' | translate">
      <button (click)="toggleBadgeVisibility(notifications)" class="active p-2 hover:rotate-6">
        <app-svg-images [svgName]="notifications.length ? 'bell-alert-solid' : 'bell-solid'" class="h-5 flex text-gray-500 dark:text-white/80"></app-svg-images>
      </button>
    </app-overlay-popup>
  } @else {
    <button (click)="toggleBadgeVisibility(notifications)" class="h-6 flex items-center gap-1 active group/button dark:text-white">
      <app-svg-images [svgName]="notifications.length ? 'bell-alert' : 'bell'" class="h-6 group-hover/button:rotate-12 transition-all"></app-svg-images>
      @if (notifications.length) {
        <span class="inline-block rounded-md bg-kohort-primary p-1 text-xs font-medium text-white group-hover/button:scale-105 transition-all">
          {{ notifications.length }}
        </span>
      }
    </button>
  }
}
